// 判断是否为移动设备，包括 iPhone、iPad、iPod、Android 和自定义的 lihan_ad
export const isMobile = userAgent =>
    /(iphone|ipad|ipod|android|lihan_ad)/i.test(userAgent)

// 判断是否为 Chrome 浏览器
export const isChrome = userAgent => /chrome/i.test(userAgent)

// 判断是否为 iOS 系统设备（iPhone、iPad 或 iPod）
export const isIOS = userAgent => /(iphone|ipad|ipod)/i.test(userAgent)

// 判断是否为 Android 系统设备，或自定义的 lihan_ad
export const isAndroid = userAgent => /(android|lihan_ad)/i.test(userAgent)

// 判断是否为微信浏览器（WeChat）
export const isWX = userAgent => /micromessenger/i.test(userAgent)

// 判断是否为 Windows 版微信或 Mac 版微信
export const isWXPc = userAgent => /(WindowsWechat|MacWechat)/.test(userAgent)

// 判断是否为微信小程序
export const isWXMini = userAgent => /miniProgram/i.test(userAgent)

// 判断是否为 QQ 浏览器或 QQ 客户端
export const isQQ = userAgent => /\sQQ\//i.test(userAgent)

// 判断是否为 TIM（QQ 的一款客户端）
export const isTimQQ = userAgent =>
    /\sQQ\//i.test(userAgent) && /TIM\//i.test(userAgent)

// 判断是否为微博浏览器
export const isWB = userAgent => /weibo/i.test(userAgent)

// 判断是否为有电（youdian）客户端
export const isYD = userAgent => /youdian/i.test(userAgent)

// 判断是否为 Piwan（丕玩）、佩玩或牛乐应用
export const isPW = userAgent => /piwan|peiwan|niule/i.test(userAgent)

// 判断是否为指定的域名之一（属于某个特定服务商或产品）
export const isDA = (hostname = '') =>
    hostname === 'h5.iniucha.com' ||  // 检查是否为指定的域名
    hostname === 'iniucha.cn' ||      // 或者子域名
    hostname.endsWith('.iniucha.cn')  // 以指定后缀结尾的域名

// 判断是否为特定的开发、测试或生产环境，或者某些特定的域名
export const isX = (hostname = '') =>
    ENVIRONMENT === 'development' ||  // 判断是否为开发环境
    ENVIRONMENT === 'test' ||         // 判断是否为测试环境
    ENVIRONMENT === 'production' ||   // 判断是否为生产环境
    hostname === 'h5-test.tinyvoice.com.cn' ||  // 检查某些特定的测试域名
    hostname === 'api-test.tinyvoice.com.cn' ||
    hostname.endsWith('.hazaclub.com') ||  // 以 hazaclub.com 结尾的域名
    hostname === 'hazaclub.com'

// 判断是否为社交平台环境，可能用于多环境配置
export const isSocial = (hostname = '') =>
    ENVIRONMENT === 'development2' ||  // 判断是否为第2组开发环境
    ENVIRONMENT === 'test2' ||         // 测试环境
    ENVIRONMENT === 'production2' ||   // 生产环境
    hostname === 'h5-test2.tinyvoice.com.cn' || // 指定测试域名
    hostname === 'api-test2.tinyvoice.com.cn' ||
    hostname.endsWith('.kissoclub.com') ||  // 以 kissoclub.com 结尾的域名
    hostname === 'kissoclub.com'

export const isHuna = (hostname = '') =>
    ENVIRONMENT === 'development3' ||  // 判断是否为第3组开发环境
    ENVIRONMENT === 'test3' ||         // 测试环境
    ENVIRONMENT === 'production3' ||   // 生产环境
    hostname === 'h5-test2.tinyvoice.com.cn' ||
    hostname === 'api-test2.tinyvoice.com.cn' ||
    hostname.endsWith('huna.live') ||
    hostname === 'huna.live'

export const isFrdyo = (hostname = '') =>
    ENVIRONMENT === 'development4' ||  // 判断是否为第4组开发环境
    ENVIRONMENT === 'test4' ||         // 测试环境
    ENVIRONMENT === 'production4' ||   // 生产环境
    hostname === 'h5-test2.tinyvoice.com.cn' ||
    hostname === 'api-test2.tinyvoice.com.cn' ||
    hostname.endsWith('.frdyo.com') ||
    hostname === 'frdyo.com'

// 判断是否为 Lihan 环境，可能与特定的域名或用户代理（APICloud）相关
export const isLH = (userAgent, hostname = '') =>
    /APICloud/i.test(userAgent) ||    // 判断是否为 APICloud 用户代理
    hostname === 'lihan.ltd' ||       // 判断是否为 lihan.ltd 域名
    hostname.endsWith('.lihan.ltd')   // 判断是否为以 .lihan.ltd 结尾的域名

// 判断是否为 APICloud 环境
export const isAPICloud = userAgent => /APICloud/i.test(userAgent)

// 判断是否为 SD 环境，特定域名
export const isSD = (hostname = '') => hostname === 'h5.sd.huk.ltd'  // 判断是否为 h5.sd.huk.ltd 域名

import Loadable from 'react-loadable'

const hunaRoutes =[
    {
        path: '/home_huna',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-HomeX" */ '../pages/home_huna'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/huna/about/dp',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutDpX" */ '../pages/aboutDp_huna'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/huna/about/ts',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutTsSocial" */ '../pages/aboutTs_huna'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/huna/about/pp',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutPpX" */ '../pages/aboutPp_huna'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/huna/about/aboutCsap',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutCsapX" */ '../pages/aboutCsap_huna'),
            loading: () => null,
        }),
    },
    // 联系我们
    {
        path: '/hybrid/huna/about/contact',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutContact" */ '../pages/aboutContact_huna'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/huna/about/contact/en',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutContactEn" */ '../pages/aboutContact_huna/aboutContactEn'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/huna/about/contact/ar',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutContactAr" */ '../pages/aboutContact_huna/aboutContactAr'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/huna/about/contact/tr',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutContactTr" */ '../pages/aboutContact_huna/aboutContactTr'),
            loading: () => null,
        }),
    },
]


export default hunaRoutes
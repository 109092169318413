import Loadable from 'react-loadable'

const frdyoRoutes =[
    // 首页
    {
        path: '/',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-Home" */ '../pages/home_frdyo'),
            loading: () => null,
        }),
    },
    {
        path: '/home_frdyo',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-HomeX" */ '../pages/home_frdyo'),
            loading: () => null,
        }),
    },
    // 帐户删除策略
    {
        path: '/frdyo/about/dp',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutDpX" */ '../pages/aboutDp_frdyo'),
            loading: () => null,
        }),
    },
    // 服务条款
    {
        path: '/frdyo/about/ts',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutTsSocial" */ '../pages/aboutTs_frdyo'),
            loading: () => null,
        }),
    },
    // 隐私政策
    {
        path: '/frdyo/about/pp',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutPpX" */ '../pages/aboutPp_frdyo'),
            loading: () => null,
        }),
    },
    // 儿童协议
    {
        path: '/frdyo/about/aboutCsap',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutCsapX" */ '../pages/aboutCsap_frdyo'),
            loading: () => null,
        }),
    },
    // 儿童协议 -  hdDownloader包的
    {
        path: '/hdDownloader/about/aboutCsap',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutCsapX" */ '../pages/aboutCsap_hddl'),
            loading: () => null,
        }),
    },
    // 联系我们
    {
        path: '/frdyo/about/contact',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutContact" */ '../pages/aboutContact_frdyo'),
            loading: () => null,
        }),
    },
    {
        path: '/frdyo/about/contact/en',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutContactEn" */ '../pages/aboutContact_frdyo/aboutContactEn'),
            loading: () => null,
        }),
    },
    // 社区指南
    {
        path: '/frdyo/about/aboutCg',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutCsapX" */ '../pages/aboutCg_frdyo'),
            loading: () => null,
        }),
    },
    // 第三方sdk
    {
        path: '/frdyo/about/about3rd',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutCsapX" */ '../pages/aboutCg_frdyo'),
            loading: () => null,
        }),
    },
]


export default frdyoRoutes